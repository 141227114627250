import React from 'react'
import SeoPricingHead from './SeoPricingHead'
import SeoPricingMain from './SeoPricingMain'

const SeoPricing = () => {
  return (
<div>
    <SeoPricingHead/>
    <SeoPricingMain/>
</div>
  )
}

export default SeoPricing
